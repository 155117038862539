<template>
    <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="100"
            cy="100"
            r="99.5"
            stroke="#8950C0"
            stroke-dasharray="5 5"
        />
        <circle cx="100" cy="100" r="86.9565" fill="url(#paint0_linear_1_93)" />
        <path
            d="M99.7446 110.087L100.109 92.3589C100.134 91.135 101.903 90.0308 104.81 89.4193L124.692 85.2444C131.527 83.8094 140.483 73.7093 140.454 88.6049L140.392 120.573C140.386 123.752 138.696 125.66 135.934 124.087C132.617 122.2 128.037 119.226 124.014 118.207L104.32 113.218C101.441 112.487 99.7195 111.311 99.7446 110.087Z"
            fill="url(#paint1_linear_1_93)"
        />
        <g filter="url(#filter0_b_1_93)">
            <rect
                x="49"
                y="79"
                width="51"
                height="42.7742"
                rx="4"
                fill="url(#paint2_linear_1_93)"
                fill-opacity="0.68"
            />
            <rect
                x="49.5"
                y="79.5"
                width="50"
                height="41.7742"
                rx="3.5"
                stroke="url(#paint3_linear_1_93)"
            />
        </g>
        <circle
            cx="91.7742"
            cy="85.5806"
            r="3.29032"
            fill="url(#paint4_linear_1_93)"
        />
        <defs>
            <filter
                id="filter0_b_1_93"
                x="34.5"
                y="64.5"
                width="80"
                height="71.7742"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.25" />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_1_93"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_1_93"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_1_93"
                x1="221.739"
                y1="334.783"
                x2="78.2609"
                y2="7.60873"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F02247" />
                <stop offset="1" stop-color="#F02247" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1_93"
                x1="141.063"
                y1="92.3986"
                x2="124.138"
                y2="133.868"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F02247" />
                <stop offset="1" stop-color="#231035" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_1_93"
                x1="106.865"
                y1="80.9443"
                x2="49.2197"
                y2="111.269"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FCFCFC" stop-opacity="0.2" />
                <stop offset="1" stop-color="white" stop-opacity="0.49" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_1_93"
                x1="41.1538"
                y1="79"
                x2="102.53"
                y2="127.161"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_1_93"
                x1="90.1973"
                y1="82.2903"
                x2="96.4407"
                y2="84.4697"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F02247" />
                <stop offset="1" stop-color="#231035" />
            </linearGradient>
        </defs>
    </svg>
</template>
